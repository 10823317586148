import { DY_KeywordSearch } from '@/src/lib/dynamicYield/events';
import { VpSearchIcon } from '@vtmn-play/icons/react';
import { useEffect, useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { formatedSearchBarKeywords } from '../../Utils';
import { Desktop } from '../common/CustomMediaQuery';
import SearchBarAnimation from '../Header/SearchBarAnimation';
import { amplitude_searchBarOpened } from '@/redux/actions/GAEventsActions/globalEvents/GASearchEvents';

let interval = null;
let searchValue = null;
let formattedKeywordsSequenceList = [];
const CustomSearchBox = ({
  currentRefinement,
  refine,
  suggestionSearch,
  setSearchOverlay,
  noSearchResults,
  className = '',
  autoFocus = true,
  showRecentSearches,
  searchKeywords,
  closeNavigation = () => {},
}) => {
  const [searchBarAnimation, setSearchBarAnimation] = useState(true);

  const onChangeHandler = (value) => {
    if (value.length === 0) {
      showRecentSearches(false);
    } else {
      showRecentSearches(true);
    }

    if (value?.length > 2) {
      interval = setInterval(() => {
        if (searchValue === value) {
          clearInterval(interval);
          refine(value);
        }
      }, 400);
    } else {
      refine('');
    }
    searchValue = value;
    suggestionSearch(false);
  };
  const handleFocus = (isFocused) => {
    if (!isFocused) {
      onChangeHandler('');
      setSearchBarAnimation(true);
    }
    showRecentSearches(true);
  };

  const toggleSearchAction = () => {
    if (searchValue == null || searchValue == '') {
      if (searchBarAnimation) {
        handleFocus(true);
        setSearchBarAnimation(!searchBarAnimation);
      }
    }
  };

  useEffect(() => {
    if (searchKeywords.keywords?.length > 0)
      formattedKeywordsSequenceList = formatedSearchBarKeywords(searchKeywords.keywords);
  }, [searchKeywords?.keywords]);
  useEffect(() => {
    autoFocus = typeof window !== 'undefined' && window?.innerWidth >= 992;
    setSearchBarAnimation(typeof window !== 'undefined' && window?.innerWidth >= 992);
  }, []);

  function truncateItem(input) {
    if (input.length > 35) {
      return `${input.substring(0, 35)}...`;
    }
    return input;
  }

  return (
    <div onClick={toggleSearchAction} className="bg-grey-50 rounded-full" data-test-id="header:search-bar">
      {(!searchBarAnimation ||
        (typeof window !== 'undefined' && window.innerWidth <= 992) ||
        !searchKeywords.keywords?.length > 0) && (
        <span className="flex items-center mx-4">
          <VpSearchIcon className="text-grey-500" />
          <input
            type="text"
            defaultValue={currentRefinement}
            onBlur={() => {
              setTimeout(() => handleFocus(noSearchResults), 500); // re-render issue
            }}
            onKeyDown={(event) => {
              if (event.keyCode === 13 && event.currentTarget.value) {
                DY_KeywordSearch({ type: 'SearchQuery', value: event.currentTarget.value });
                let recentSearch = JSON.parse(localStorage.getItem('recentSearch')) || [];
                if (recentSearch.includes(event.currentTarget.value)) {
                  recentSearch = recentSearch.filter((item) => item != event.currentTarget.value);
                }
                recentSearch.unshift(event.currentTarget.value);
                if (recentSearch.length > 5) {
                  recentSearch.pop();
                }
                localStorage.setItem('recentSearch', JSON.stringify(recentSearch));
                window.location.href = `/search?query=${event.currentTarget.value}`;
              }
            }}
            onChange={(event) => onChangeHandler(event.currentTarget.value)}
            onFocus={() => {
              handleFocus(true);
              amplitude_searchBarOpened();
              setSearchOverlay(true);
              closeNavigation();
            }}
            placeholder={
              searchKeywords.placeholder || (typeof window !== 'undefined' && window?.innerWidth < 420)
                ? truncateItem('Search For 60+ Sports and 6000+ Products')
                : 'Search For 60+ Sports and 6000+ Products'
            }
            className={` ${className}  bg-grey-50 text-[#616161] md:py-4 lg:pb-3 lg:pt-3`}
            id="search"
            autoComplete="off"
            autoFocus={autoFocus || !searchBarAnimation}
          />
        </span>
      )}

      <Desktop>
        {formattedKeywordsSequenceList.length > 0 && (
          <SearchBarAnimation
            speed={1}
            formattedKeywordsSequenceList={formattedKeywordsSequenceList}
            searchBarAnimation={searchBarAnimation}
          />
        )}
      </Desktop>
    </div>
  );
};

export default connectSearchBox(CustomSearchBox);
